@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&family=Rosarivo&display=swap');

* {
  margin: 0;
  padding: 0;
}

a {
  color: var(--link-color);
  text-decoration: none;
  font-weight: 600;
}

$max-width: 1200px;

$font-size: 16px;
$font-family-base: 'Roboto', sans-serif;
$font-family-heading: 'Rosarivo', serif;

:root {
  --main-color: #637330;
  --secondary-color: #CCDA8C;
  --background-color: #D7D5D6;
  --link-color: #21221C;

  --max-width: #{$max-width};

  --font-size: #{$font-size};
  --font-family-base: #{$font-family-base};
  --font-family-heading: #{$font-family-heading};
}
