@import '../../base.module.scss';

.image {
  width: 100%;
  height: 90vh;
  background-color: black;

  &__figContainer {
    position: relative;
    height: 100vh;
  }

  &__figure {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    margin: 0;

    img {
      object-fit: cover;
    }

    @media (max-width: 700px) {
      height: 90vh;
      width: 100vw;
      opacity: 0.8;
    }
  }

  &__logo {
    width: 50em;
    position: absolute;
    bottom: 90px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;

    @media (max-width: 700px) {
      width: 20em;
      bottom: 25%;
    }
  }

  &__pic {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }

  &__blur {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    z-index: -10;
    opacity: 0.5;
    top: 0;
    background-position: center;
  }

  // &__button {
  //   position: absolute;
  //   height: 15vh;
  //   width: 10vw;
  //   bottom: 0;
  //   right: 0;
  //   background-color: transparent;
  //   border: 0;
  //   z-index: 10;
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   margin: 3rem;
  // }
}