$main-color: #CCDA8C;
$secondary-color: #3D7068;
$background-color: #D7D5D6;

.page {
  background-color: $main-color;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;

  // Mobile
  @media (max-width: 700px) {
    display: block;
    width: 100vw;
  }

  &__coverimage {
    height: 100vh;
    width: 100vw;
    background-color: red;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .container {
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    width: 100%;
    height: 100vh;

    // Mobile
    @media (max-width: 700px) {
      width: 100vw;
      height: 100%;
      scroll-snap-type: none;
      overflow-y:auto;
    }
  }
  
  .section {
    height: 100vh;
    text-align: center;
    scroll-snap-align: start;

    // Mobile
    @media (max-width: 700px) {
      height: 100%;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    height: 100%;

    // Mobile
    @media (max-width: 700px) {
      display: block;
      width: 100%;
    }
  }

  .about__texts {
      width: 50%;
      text-align: left;
      position: relative;
      margin-left: 70px;
      margin-right: 70px;
      display: flex;
      align-items: center;

      .title {
        margin-bottom: 20px;
        font-family: var(--font-family-base);
      }
      .bigText {
        margin-bottom: 18px;
        font-family: var(--font-family-heading);
        font-weight: 400;
        font-size: 24px;
      }
      .smallText {
        font-family: var(--font-family-base);
      }

      // Mobile
      @media (max-width: 700px) {
        width: 100vw;
        margin: 0px;
        padding: 15px;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display:block;

        .title {
          margin-top: 40px;
        }
      }
  }
  .about__image {
    width: 50%;
    object-fit: cover;
    
    // Mobile
    @media (max-width: 700px) {
      width: 100%;
      display: block;
      padding: 15px;
      -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
  }
}