@import '../../base.module.scss';

.tabs {
  width: 100%;
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem;

  &__header {
    display: grid;
    grid-template-columns: repeat(4, 0.5fr);
    gap: 10px;
    width: 100%;
    margin-bottom: 5rem;

    @media (max-width: 700px) {
      display: block;
      padding-top: 30px;
    }
  }

  &__headerText {
   //border-bottom: 3px solid var(--main-color);
    cursor: pointer;
    font-family: var(--font-family-base);
    font-weight: 400;
    font-size: 20px;

    &__current {
      //border-bottom: 2px solid black;
      font-family: var(--font-family-base);
      text-decoration: underline;
      cursor: pointer;
      font-weight: 400;
      font-size: 20px;
    }
  }
}

.contentGroup {
  width: 100%;
  display: grid;
  border: 4pt solid green;

  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  gap: 10px;

  &__link {
    margin: 0.5em;
    width: fit-content;
  }

  &__admin {
    grid-column: span 2;
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.contentIcon {
  width: 100%;

  h1 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  // display: grid;
  // border: 4pt solid red;

  // grid-template-columns: repeat(8, 1fr);
  // grid-template-rows: auto;
  // gap: 10px;

  @media (max-width: 700px) {
    padding-top: 2rem;
  }
}

.contentRef {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: scroll;

  h1 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.ref {
  margin: 1em 1em 1em 1em;

  &__reference {
    font-size: 1.15em;

    & > a {
      font-size: 0.8em;
      text-decoration: underline;
      font-weight: 700;
    }
  }

  &__desc {
    font-size: 0.9em;
    font-style: italic;
  }

  &__doi {
    font-size: 0.9em;
  }
}

.popOver {
  margin: 1em;

  &__text {
    font-weight: 600;
  }
}

.button {
  margin: 0 1rem 0 1rem;
  border: 0;
  background-color: green;
  color: white;
  padding: 0.2rem;
  border-radius: 5px;
}

.background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba(255, 255, 255, .6);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  width: fit-content;
  padding: 2em;
  background-color: var(--main-color);
  border: 5px solid var(--secondary-color);
  border-radius: 15px;

  &__header {
    margin: 0 0 1em 0;

  }

  &__options {
    display: flex;
    justify-content: space-around;
  }

  &__button {
    font-size: 1.2em;
    border: 3px solid var(--secondary-color);
    border-radius: 5px;
    cursor: pointer;
  }
}


// NÝTT
.divided_layout {
  display: flex;

  // Mobile
  @media (max-width: 700px) {
    display: block;
  }
}

.left_column {
  width: 50%;
  margin-top: 3rem;
  padding: 0px 70px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

  // Mobile
  @media (max-width: 700px) {
    width: 100%;
    padding: 0px 15px;
    padding-bottom: 3rem;
  }
}

.right_column {
  width: 50%;
  background-color: white;
  height: 100vh;
  z-index: 10;
  position: sticky; 
  top: 0; 
  right: 0;
  position: absolute;
  padding: 0px 70px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

  // Mobile
  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    position: static; 
    padding: 0px 15px;
    padding-bottom: 3rem;
  }
}

.bigText {
  margin-bottom: 30px;
  font-family: var(--font-family-heading);
  font-weight: 400;
  font-size: 24px;
}
.smallText {
  font-family: var(--font-family-base);
}

.data_table {
  max-height: calc(100vh - 236px);
  height: 100%;
  overflow-y: scroll;

  // Mobile
  @media (max-width: 700px) {
    max-height: none;
  }

  tr:first-child{
    border-top: 0px;
  }
  td {
    a {
      font-weight: normal;
    }
  }
  thead{
    th {
      font-weight: bold;
    }
  }
}

.data_table_header {
  position: sticky;
  top: 0;
  background: linear-gradient(to bottom, white 98%, gray 100% );
}

.cover_img {
  margin-top: 3rem;
  width: 75%;
}