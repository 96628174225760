.page {
  display: flex;
  width: 100%;
  height: 100vh;
  //align-items: center;
  flex-direction: column;
  background-color: #CCDA8C;

  // Mobile
  @media (max-width: 700px) {
    display: block;
    height: auto;
  }

  &__content {
    width: 100%;
    //justify-content: center;
  }
}