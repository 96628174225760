.description {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  overflow-x: auto;
  height: 60vh;

    // Mobile
    @media (max-width: 700px) {
      height: auto;
    }

  &__text {
    margin-top: 1rem;

    &__bold {
      font-weight: bold;
    }

    &__italic {
      font-style: italic;
    }

    &__boldNItalic {
      font-weight: bold;
      font-style: italic;
    }
  }

  &__link {
    align-self: flex-start;
    cursor: pointer;
    border: 0;
    background-color: white;
    margin-top: 1em;
  }

  &__inputText {
    width: 100%;
    height: fit-content;
    border: 0;
    height: 10em;
  }
}

.submitBtn {
  margin-left: 1em;
}

.adminInstructions {
  margin-left: 1em;
  margin-bottom: 1em;

  &__bold {
    font-weight: bold;
  }

  &__italic {
    font-style: italic;
  }

  &__boldNitalic {
    font-weight: bold;
    font-style: italic;
  }
}