.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  max-width: 40%;
  width: fit-content;
  height: 30%;

  @media (max-width: 700px) {
    max-width: 100%;
  }
}

.type {
  display: flex;
  flex-direction: row;

  &__radio {
    margin: 1rem;
  }
}

.fileUpload {
  margin: 1rem;
}

.reference {
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0 1rem;
  width: 40vw;

  &__group {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }

  &__text {
    width: 100%;
    height: 3.5rem;

    overflow-wrap: break-word;
    resize: none;
  }

  @media (max-width: 700px) {
    width: auto;
  }
}

.majorGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  margin: 0 1rem 0 1rem;
  width: 20vw;

  @media (max-width: 700px) {
    width: auto;
  }

  &__text {
    width: 100%;
    height: 2rem;

    overflow-wrap: break-word;
    resize: none;
  }
}

.submitBtn {
  margin: 1rem;
  margin-bottom: 3rem;
}