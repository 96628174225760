.iconWrapper {
  // width: 5em;
  // height: 5em;
  margin: 0.2em 0.5em 0.2em 0.5em;
  // border: 1px solid black;
  border-radius: 5px;
  position: relative;
}

.icon {
  border: 0.5px solid lightgrey;
  background-color: white;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 15px;
  padding: 50%;
  // height: 100%;
}
