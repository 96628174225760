@import '../../base.module.scss';

.container {
  display: flex;
  //flex-direction: column;
  //max-width: var(--max-width);
  // Mobile
  @media (max-width: 700px) {
    display: flex;
    padding-top: 60px;
    background: white;
    flex-flow: column-reverse;
  }
  
  hr {
    margin-bottom: 2em;
    border: 0.1px solid #e5e5e5;
  }

  h1 {
    font-family: var(--font-family-heading);
  }
  
}

.leftSide {
  width: 50%;
  margin-top: 3rem;
  padding: 0px 70px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;

  // Mobile
  @media (max-width: 700px) {
    width: 100%;
    padding: 0px 15px;
  }
}

.rightSide {
  width: 50%;
  background-color: white;
  height: 100vh;
  z-index: 10;
  position: sticky;
  top: 0;
  right: 0;
  position: absolute;
  padding: 0px 70px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  overflow-x: auto;

  // Mobile
  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    z-index: 0;
    position: static;
    padding: 0px 15px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
}

.mapContainer {
  width: 100%;
  display: flex;

}

.map {
  width: 80%;

  &__expanded {
    width: 100%;
  }
}

.slider {
  height: auto;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__shrunk {
    opacity: 0;
  }
}