.cookieRequest {
  position: fixed;
  background-color: lightgrey;
  border-top: 10px solid var(--secondary-color);
  border-bottom: 10px solid var(--secondary-color);

  // border-radius: 15px;
  opacity: 1;
  z-index: 200;

  bottom: 0;
  left: 0;

  padding-top: 1em;
  padding-bottom: 1em;

  width: 100%;

  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
}

.textBox {
  width: 100%;
  margin-left: 1em;
}

.text {
  font-size: 1.25em;
  margin: 0;

  @media (max-width: 700px) {
    font-size: 1em;
    margin-right: 1.5em;;
  }
}

.button {
  align-self: flex-end;

  background-color: var(--main-color);
  border: 4px solid var(--secondary-color);
  border-radius: 10px;

  padding: 0.5em;

  font-size: 1.25em;

  cursor: pointer;
  margin: 1em;

}