@import '../../base.module.scss';

.page {
  height: 100vh;
  width: 100%;
  background-color: #CCDA8C;

  &__links {
    display: flex;
    //justify-content: center;
    margin-top: 3rem;

     // Mobile
     @media (max-width: 700px) {
      display: block;
    }
  }
}