.iconWrapper {
  width: 100%;
  height: 5rem;
  // margin: 0.2em 0.5em 0.2em 0.5em;
  // border: 1px solid black;
  border-radius: 5px;
  position: relative;
}

.icon {
  border: 0.5px solid lightgrey;
  background-color: white;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  width: 100%;
  height: 100%;

  &__units {
    position: absolute;
    z-index: 10;
    margin: 3px;
    bottom: 0;
    right: 0;
  }
}

.popover {

}

.popper__text {
  background-color: lightgray;
  // border: 1px solid #666;
  opacity: 1;
  z-index: 100;
  position: relative;
  top: 1em;
  padding: 0.2em 0.5em 0.2em 0.5em;
  border-radius: 5px;

}