@import '../../base.module.scss';

.mapContainer {
  margin-bottom: 2em;
  margin-top: 2em;
  width: 100%;
  //height: 70vw;
  position: relative;
}

.image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
  padding-top: 2em;
}

.map {
  // opacity: 0;
  z-index: 10;
}

.mapHeader {
  height: 2em;
  display: flex;
  justify-content: space-between;

  &__year {
    margin: 0;
    font-size: 1em;
    display: flex;
    align-items: flex-end;
  }

  &__expandShrink {
    border: none;
    background-color: white;
    display: flex;
    padding: 0;
    align-items: flex-end;
    text-decoration: underline;
    font-size: 1em;
    cursor: pointer;
  }
}

svg {
  path {
    fill: var(--secondary-color);
    fill-opacity: 0.1;

  // Mobile
  @media (max-width: 700px) {
    fill-opacity: 0.8;
  }


    &[aria-current='true'] {
      fill: var(--secondary-color);
      fill-opacity: 1;
    }
  }
}

*:focus {
  outline: none;
}