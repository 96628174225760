.page {
  width: 100vw;
  height: 100vh;
}

.form {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}