.page {
  height: 100vh;
  width: 100%;
  background-color: #CCDA8C;

  // Mobile
  @media (max-width: 700px) {
    height: auto;
  }
  

  &__content {
    display: flex;
    //justify-content: center;
  }
}