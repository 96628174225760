@import '../../base.module.scss';


.selectionBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2em;
  margin-top: 2em;

  &__buttons {
    &__buildings {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;

      @media (max-width: 700px) {
        grid-template-columns: repeat(3, 1fr);;
      }
    }

    &__icons {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      gap: 10px;

      @media (max-width: 700px) {
        grid-template-columns: repeat(4, 1fr);;
      }
    }
  }

  &__button {
    border: 0.5px solid lightgrey;
    background-color: white;
    border-radius: 5px;
    width: 100%;
    height: 5rem;
    padding: 1em;

    &__hovering {
      border: 0.5px solid lightgrey;
      background-color: var(--secondary-color);
      border-radius: 5px;
      width: 100%;
      height: 5rem;
      color: #fff;
      padding: 1em;
    }
  }

  &__seperator {
    margin: 1em;
    margin-left: 6em;
    margin-right: 6em;
    border-bottom: 1px solid black;
  }
}

.title {
  //margin: 0.5em 1em 0em 1em;
  margin-bottom: 1em;
  //text-decoration: underline;
  font-size: 1.5em;
}