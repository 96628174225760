@import '../../base.module.scss';

.container {
  display: flex;
  margin-bottom: 3rem;

  // Mobile
  @media (max-width: 700px) {
    display: flex;
    padding-top: 90px;
    background: white;
    flex-flow: column-reverse;
  }

  h2 {
    margin-bottom: 1em;
  }
  a {
    font-weight: 400;
    text-decoration: underline;
  }

  hr {
    margin-bottom: 2em;
    border: 0.1px solid #e5e5e5;
  }
}

.leftSide {
  width: 50%;
  margin-top: 3rem;
  padding: 0px 70px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;

  // Mobile
  @media (max-width: 700px) {
    width: 100%;
    padding: 0px 15px;
    margin-top: 1rem;
  }
}

.rightSide {
  width: 50%;
  background-color: white;
  height: 100vh;
  z-index: 10;
  position: sticky;
  top: 0;
  right: 0;
  position: absolute;
  padding: 0px 70px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  overflow-x: auto;
  padding-top: 3rem;

  // Mobile
  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    z-index: 0;
    position: static;
    padding: 0px 15px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    overflow-x: initial;
    display: flex;
    flex-wrap: wrap;

    a {
      order: -1;
    }
  }

}

.building {
  &__header {
    font-family: var(--font-family-heading);
  }
}

.mapContainer {
  width: 100%;
}

.mapImage {
  width: 100%;
}

.back_btn {
  padding-bottom: 40px;
}