// $main-color: #279AF1;
// $secondary-color: #3D7068;
// $background-color: #D7D5D6;

@import '../../base.module.scss';

.navbar{
  width: 100%;
  min-height: 6vh;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eee;
  transition: all .7s ease-in;
}

.scrolled {
    position: fixed;
    z-index: 100;
    top: 40px;
    margin-left: 70px;
    margin-right: auto;
    left: 0;
    text-align: left;
    right: 0;
    width: 50em;

    a {
        color: black;
        font-size: 16px;
        margin-right: 50px;
        font-family: var(--font-family-heading);
    }
    a:hover {
        text-decoration: underline;
    }
}

.mobile_navbar {
    background-color: var(--link-color);
    margin-left: 0px;
    top: 0px;
    padding: 15px 10px;
    z-index: 200;
    display: block;
    position: fixed;
    left: 0;
    text-align: left;
    right: 0;

    a {
        font-size: 12px;
        color: white;
        margin-right: 50px;
        font-family: var(--font-family-heading);
    }
    a:hover {
        text-decoration: underline;
    }
}

.header_scrolled {
    position: fixed;
    z-index: 100;
    bottom: 40px;
    margin-left: auto;
    width: 50em;
    margin-right: auto;
    left: 0;
    text-align: left;
    right: 0;

    a {
        color: white;
        font-size: 16px;
        margin-right: 50px;
        font-family: var(--font-family-heading);
    }
    a:hover {
        text-decoration: underline;
    }

    // hide coverimage navbar on mobile
    @media (max-width: 700px) {
        display: none;
    }
}

.not_home_page {
    z-index: 100;
    margin-left: 70px;
    margin-right: auto;
    padding-top: 40px;
    width: 50em;
    text-align: left;

    a {
        color: black;
        font-size: 16px;
        margin-right: 50px;
        font-family: var(--font-family-heading);
    }
    a:hover {
        text-decoration: underline;
    }
    
    // Top navbar on mobile
    @media (max-width: 700px) {
        display: none;
    }
}